import {
    CategoryFilterOverlay,
    CategoryPageComponent as SourceCategoryPageComponent,
    CategorySort,
    FilterIcon,
    GridIcon,
    ListIcon,
} from 'SourceRoute/CategoryPage/CategoryPage.component';

import CategoryDetails from 'Component/CategoryDetails';
import CategoryItemsCount from 'Component/CategoryItemsCount';
import CategoryProductList from 'Component/CategoryProductList';
import { CategorySortField } from 'Component/CategorySort/CategorySort.type';
import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { ReactElement } from 'Type/Common.type';
import { isCrawler, isSSR } from 'Util/Browser';
import BrowserDatabase from 'Util/BrowserDatabase';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';

import {
    CategoryDisplayMode,
    CategoryPageLayout,
    LAYOUT_KEY,
} from './CategoryPage.config';
import { CategoryPageComponentProps, CategoryPageComponentState } from './CategoryPage.type';

import './CategoryPage.override.style';

export {
    CategoryFilterOverlay,
    FilterIcon,
    GridIcon,
    ListIcon,
    CategorySort,
};

/** @namespace AlmusbahblendPwa/Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPageComponent {
    // TODO implement logic

    renderCategoryProductList() {
        const {
            filter,
            search,
            selectedSort,
            selectedFilters,
            isMatchingListFilter,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            category,
        } = this.props;

        // console.log("isCurrentCategoryLoaded: ", isCurrentCategoryLoaded);
        // console.log("category data: ", category);

        let currentCategory = {};

        if (isCurrentCategoryLoaded) {
            currentCategory = category;
        }

        const { activeLayoutType } = this.state;

        if (!this.displayProducts()) {
            setLoadedFlag();

            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="ProductListWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { this.renderItemsCount(true) }
                <CategoryProductList
                  category={currentCategory}
                  filter={ filter }
                  search={ search }
                  sort={ selectedSort }
                  selectedFilters={ selectedFilters }
                  isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                  isMatchingListFilter={ isMatchingListFilter }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  layout={ activeLayoutType || CategoryPageLayout.GRID }
                />
            </div>
        );
    }
}

export default CategoryPageComponent;
