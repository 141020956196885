/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { GQLCurrencyEnum } from 'Type/Graphql.type';
import { formatPrice, formatPriceFilter, formatPriceFilterBoth } from 'Util/Price';

/** @namespace Util/Category/Filters/getPriceFilterLabel */
export const getPriceFilterLabel = (
    from,
    to,
    currencyCode,
) => {
    if (currencyCode == "SAR") {
        const priceFrom = formatPriceFilter("from", Number(from), currencyCode);
        const priceTo = formatPriceFilter("to", Number(to), currencyCode);
    
        if (from === '*') {
            return formatPriceFilter("to", Number(to), currencyCode);
        }
    
        if (to === '*') {
            return formatPriceFilter("from", Number(from), currencyCode);
        }
    
        return formatPriceFilterBoth(Number(from), Number(to), currencyCode);
    } else {
        const priceFrom = formatPrice(Number(from), currencyCode);
        const priceTo = formatPrice(Number(to), currencyCode);

        if (from === '*') {
            return __('Up to %s', priceTo);
        }

        if (to === '*') {
            return __('From %s', priceFrom);
        }

        return __('From %s to %s', priceFrom, priceTo);
    }
};

/** @namespace Util/Category/Filters/getFiltersCount */
export const getFiltersCount = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filters,
) => Object.values(filters).reduce((prev, next) => prev + next.length, 0);
